import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import {Col, Container, Row} from 'react-bootstrap'
import Moment from 'moment';
import {Trans} from 'gatsby-plugin-react-i18next';

const News = ({ data }) => {
  const news = data.allContentfulNews.edges
  return (
    <Layout title="Varmaorka News">
      <div className="py-offset">
        <Container>
          <Row className="align-items-center mb-md-5 mb-4">
            <Col md={12}>
              <h2 className="section-title section-title-inner"><Trans>News</Trans></h2>
            </Col>
          </Row>

          <Row>
            {news.map((news) => {
              return (
                <Col md={4} className="mb-5" key={news.node.date}>
                  <Link to={news.node.slug} className="card-image card-image-inner mb-3 d-block card-image-sm">
                    <GatsbyImage image={news.node.image.gatsbyImageData} alt={news.node.image.title} />
                    <div className="card-image-overlay">
                      <p>{Moment(news.node.date).format('ll')}</p>
                      <h3>{news.node.title}</h3>
                    </div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($language: String!){
  allContentfulNews (sort: {fields: [date], order: DESC}, filter: {node_locale: {eq: $language}}){
    edges {
      node {
        image {
          gatsbyImageData
          title
        }
        date
        slug
        title
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default News
